import { Obj } from "interface/common";
import {
  CREATE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  GET_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
} from "redux-saga/actions";
import {
  CREATE_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY_SUCCESS,
  GET_BLOG_CATEGORY_SUCCESS,
  UPDATE_BLOG_CATEGORY_SUCCESS,
} from "./reducers";

export const getBlogCategory = () => {
  return {
    type: GET_BLOG_CATEGORY,
  };
};

export const getBlogCategorySuccess = (data: Obj) => ({
  type: GET_BLOG_CATEGORY_SUCCESS,
  payload: data,
});

export const updateBlogCategory = (data: {
  id: number;
  description: string;
}) => ({
  type: UPDATE_BLOG_CATEGORY,
  payload: data,
});

export const updateBlogCategorySuccess = (data: Obj) => ({
  type: UPDATE_BLOG_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteBlogCategory = (data: number) => ({
  type: DELETE_BLOG_CATEGORY,
  payload: data,
});

export const deleteBlogCategorySuccess = (data: number) => ({
  type: DELETE_BLOG_CATEGORY_SUCCESS,
  payload: data,
});

export const createBlogCategory = (data: Obj) => ({
  type: CREATE_BLOG_CATEGORY,
  payload: data,
});

export const createBlogCategorySuccess = (data: Obj) => ({
  type: CREATE_BLOG_CATEGORY_SUCCESS,
  payload: data,
});
