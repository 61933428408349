import { Action, Obj, ResponseMeta } from "interface/common";

export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const GET_BLOG_DETAIL_SUCCESS = "GET_BLOG_DETAIL_SUCCESS";
export const PUBLISH_BLOG_SUCCESS = "PUBLISH_BLOG_SUCCESS";
export const UNDO_PUBLISH_BLOG_SUCCESS = "UNDO_PUBLISH_BLOG_SUCCESS";
export const HIGHLIGHT_BLOG_SUCCESS = "HIGHLIGHT_BLOG_SUCCESS";

interface BlogState {
  blogs: Obj[];
  blogDetail: null | Obj;
  success: boolean;
  meta: ResponseMeta;
}

const initState: BlogState = {
  blogs: [],
  blogDetail: null,
  success: false,
  meta: {
    page: 1,
    size: 10,
    total: 0,
  },
};

export const Blog = (
  state: BlogState = initState,
  action: Action
): BlogState => {
  switch (action.type) {
    case GET_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: action.payload.data,
        success: true,
        meta: {
          page: action.payload.currentPage,
          size: 10,
          total: action.payload.totalItems,
        },
      };
    }
    case UPDATE_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: state.blogs.map((blog) => {
          if (blog.id === action.payload.id) {
            return action.payload;
          }
          return blog;
        }),
        blogDetail: action.payload,
      };
    }

    case DELETE_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: state.blogs.filter((blog) => blog.id !== action.payload),
      };
    }

    case CREATE_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: [action.payload, ...state.blogs],
      };
    }

    case GET_BLOG_DETAIL_SUCCESS: {
      return {
        ...state,
        blogDetail: action.payload,
      };
    }

    case PUBLISH_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: state.blogs.map((blog) => {
          if (blog.id === action.payload.id) {
            blog.status = action.payload.status;
          }
          return blog;
        }),
      };
    }

    case HIGHLIGHT_BLOG_SUCCESS: {
      return {
        ...state,
        blogs: state.blogs.map((blog) => {
          if (blog.id === action.payload.id) {
            blog.highlight = action.payload.highlight;
          }
          return blog;
        }),
      };
    }
    default:
      return state;
  }
};

export default Blog;
