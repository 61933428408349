import { all } from "redux-saga/effects";
import blogSagas from "./Blog/sagas";
import blogCategory from "./BlogCategory/sagas";
import loginSagas from "./Login/sagas";
import newsSagas from "./News/sagas";
import profileSagas from "./Profile/sagas";
import themeSagas from "./Theme/sagas";
import humanSagas from "./Human/sagas";
import contactSagas from "./Contact/sagas";

function* sagas() {
  yield all([
    blogSagas(),
    blogCategory(),
    loginSagas(),
    newsSagas(),
    profileSagas(),
    themeSagas(),
    humanSagas(),
    contactSagas(),
  ]);
}

export default sagas;
