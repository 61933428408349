import { Action, Obj, ResponseMeta } from "interface/common";

export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";

interface ThemeState {
  cover?: string;
  about?: string;
  contact?: string;
  paper?: string;
  news?: string;
  champions?: string;
}

const initState: ThemeState = {};

export const Theme = (
  state: ThemeState = initState,
  action: Action
): ThemeState => {
  switch (action.type) {
    case GET_THEME_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPDATE_THEME_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default Theme;
