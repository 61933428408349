import { useEffect } from "react";

const ScrollTop = (props: { children: JSX.Element }) => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return props.children;
};

export default ScrollTop;
