import { Action, Human, Obj, ResponseMeta } from "interface/common";

export const GET_HUMAN_SUCCESS = "GET_HUMAN_SUCCESS";
export const ADD_HUMAN_SUCCESS = "ADD_HUMAN_SUCCESS";
export const UPDATE_HUMAN_SUCCESS = "UPDATE_HUMAN_SUCCESS";
export const DELETE_HUMAN_SUCCESS = "DELETE_HUMAN_SUCCESS";

interface HumanState {
  human: Human[];
}

const initState: HumanState = {
  human: [],
};

export const HumanReducer = (
  state: HumanState = initState,
  action: Action
): HumanState => {
  switch (action.type) {
    case GET_HUMAN_SUCCESS: {
      return {
        ...state,
        human: action.payload,
      };
    }

    case ADD_HUMAN_SUCCESS: {
      return { ...state, human: [action.payload, ...state.human] };
    }

    case UPDATE_HUMAN_SUCCESS: {
      return {
        ...state,
        human: state.human.map((h) => {
          if (h.id === action.payload.id) {
            return action.payload;
          }

          return h;
        }),
      };
    }

    case DELETE_HUMAN_SUCCESS: {
      return {
        ...state,
        human: state.human.filter((h) => h.id !== action.payload),
      };
    }

    default:
      return state;
  }
};

export default HumanReducer;
