import Sidebar from "../Sidebar";
import { SidebarItem } from "interface/sidebar";
import "./styles.scss";

interface WebShellProps {
  children?: JSX.Element;
  child?: JSX.Element;
  menu: SidebarItem[];
  prefixComponent?: JSX.Element;
  backRoute?: string;
}

const WebShell = (props: WebShellProps) => {
  return (
    <div className="WebShell">
      <div className="LeftSection">
        <Sidebar
          menu={props.menu}
          prefixComponent={props.prefixComponent}
          backRoute={props.backRoute}
        />
      </div>
      <div className="RightSection">{props.children}</div>
    </div>
  );
};

export default WebShell;
