import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { notificationServerError, notificationSuccess } from "utils/common";

import { Action } from "interface/common";
import {
  CHANGE_NEWS_HIGHLIGHT,
  CREATE_NEWS,
  DELETE_NEWS,
  GET_NEWS,
  GET_NEWS_DETAIL,
  PUBLISH_NEWS,
  UPDATE_NEWS,
} from "redux-saga/actions";
import {
  changeNewsHightlightSuccess,
  createNewsSuccess,
  deleteNewsSuccess,
  getNewsDetailSuccess,
  getNewsSuccess,
  publishNewsSuccess,
  updateNewsSuccess,
} from "./actions";

//Get news
function* doGetNews(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/posts-pdf/${action.payload.type}`,
      REQUEST_METHOD.GET,
      action.payload,
      undefined,
      true
    );
    yield put(getNewsSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getNews() {
  yield takeLatest(GET_NEWS, doGetNews);
}

//get news detail
function* doGetNewsDetail(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/post-pdf/${action.payload}`,
      REQUEST_METHOD.GET,
      undefined,
      undefined,
      true
    );

    yield put(getNewsDetailSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getNewsDetail() {
  yield takeLatest(GET_NEWS_DETAIL, doGetNewsDetail);
}

//Update news
function* doUpdateNews(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/post-pdf`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(updateNewsSuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* updateNews() {
  yield takeLatest(UPDATE_NEWS, doUpdateNews);
}
//Delete news
function* doDeleteNews(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/post-pdf/${action.payload}`,
      REQUEST_METHOD.DELETE,
      undefined,
      undefined,
      true
    );
    yield put(deleteNewsSuccess(action.payload));
  } catch (error) {
    notificationServerError((error as any).response.data.meta.code);
  }
}

function* deleteNews() {
  yield takeLatest(DELETE_NEWS, doDeleteNews);
}
//create news
function* doCreateNews(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/post-pdf`,
      REQUEST_METHOD.POST,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(createNewsSuccess(response.data));
    notificationSuccess({ content: "Tạo bài viết thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* createNews() {
  yield takeLatest(CREATE_NEWS, doCreateNews);
}

//create news
function* doPublishNews(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/post-pdf/${action.payload.id}/status`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      {
        status: action.payload.status,
      }
    );
    yield put(publishNewsSuccess(action.payload));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* publishNews() {
  yield takeLatest(PUBLISH_NEWS, doPublishNews);
}

function* doChangeHighlight(action: Action) {
  try {
    yield query(
      `/api/post-pdf/${action.payload.id}/highlight`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      {
        highlight: action.payload.highlight,
      }
    );
    yield put(changeNewsHightlightSuccess(action.payload));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* changeHighlight() {
  yield takeLatest(CHANGE_NEWS_HIGHLIGHT, doChangeHighlight);
}

export default function* newsSagas() {
  yield all([
    getNews(),
    updateNews(),
    deleteNews(),
    createNews(),
    getNewsDetail(),
    publishNews(),
    changeHighlight(),
  ]);
}
