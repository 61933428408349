import { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  DribbbleOutlined,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { SidebarItem as ISidebarItem } from "interface/sidebar";
import logo from "assets/images/img/logo.png";
import "./styles.scss";
import { replaceParamsById } from "utils/httpClient";

interface SidebarProps {
  menu: ISidebarItem[];
  prefixComponent?: JSX.Element;
  backRoute?: string;
}

interface SidebarItemProps extends ISidebarItem {
  currentRoute: string;
  active?: boolean;
  showSubMenu?: boolean;
  disabled?: boolean;
}

const SidebarItem = (props: SidebarItemProps) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <div
        className={`SidebarItem ${
          props.currentRoute.includes(props.route) ? "Active" : ""
        } ${props.disabled ? "Disabled" : ""}`}
        onClick={(event) => {
          if (props.disabled) {
            return;
          }
          if (location.pathname === props.route) {
            window.location.reload();
          } else {
            history.push(props.route);
          }
        }}
      >
        <div className="MainMenu">
          <span className="icon">
            <props.icon />
          </span>
          <span className="link">{props.title}</span>
        </div>
      </div>
      {props.showSubMenu && (
        <div className="SubMenu">
          {props.subMenu?.map((item) => (
            <div
              className={`SubMenuItem ${
                location.pathname.includes(item.route) && "Active"
              }`}
              key={item.route}
            >
              <Link to={item.route}>{item.title}</Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const Sidebar = (props: SidebarProps) => {
  const params = useParams<{ [key: string]: string }>();
  const history = useHistory();
  const location = useLocation();
  const [isMiniMize, setMiniMize] = useState(false);

  const toggleMinimize = () => {
    setMiniMize((prev) => !prev);
  };

  return (
    <>
      <div
        className={`SideBarPlaceholder ${isMiniMize ? "Minimize" : ""}`}
      ></div>
      <div className={`Sidebar ${isMiniMize ? "Minimize" : ""}`}>
        <div className="MainSideBar">
          <div className="ToggleSection">
            {!isMiniMize && (
              <div className="Logo">
                {props.backRoute && (
                  <LeftOutlined
                    onClick={() => {
                      history.push(props.backRoute!);
                    }}
                  />
                )}{" "}
                <img src={logo} alt="logo" />
              </div>
            )}
          </div>
          {!isMiniMize && props.prefixComponent}
          {props.menu.map((item) => {
            const pathName = location.pathname;
            return (
              <SidebarItem
                currentRoute={pathName}
                key={item.route}
                title={item.title}
                icon={item.icon}
                disabled={item.disabled}
                route={
                  item.param
                    ? replaceParamsById(
                        item.route,
                        `:${item.param}`,
                        params[item.param]
                      )
                    : item.route
                }
                subMenu={item.subMenu}
                showSubMenu={
                  pathName.includes(item.route) &&
                  item.subMenu?.find(
                    (sub) =>
                      sub.route === pathName || pathName.includes(sub.route)
                  ) !== undefined
                }
              />
            );
          })}
        </div>
        <div className="BottomSidebar">
          <SidebarItem
            currentRoute={"ffff"}
            title={"Cộng đồng"}
            icon={DribbbleOutlined}
            route={"wwww"}
            subMenu={[]}
            showSubMenu={false}
          />
          <SidebarItem
            currentRoute={"wwww"}
            title={"Trợ giúp"}
            icon={QuestionCircleOutlined}
            route={"fff"}
            subMenu={[]}
            showSubMenu={false}
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
