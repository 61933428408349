import { AxiosResponse } from "axios";
import { Action } from "interface/common";
import { all, takeLatest } from "redux-saga/effects";
import { notificationError } from "utils/common";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { LOGIN } from "../actions";

//Log in
function* doLogin(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      "/api/authenticate",
      REQUEST_METHOD.POST,
      undefined,
      undefined,
      false,
      action.payload
    );

    // yield put(loginSuccess(response.data));
    action.handleSuccess && action.handleSuccess(response.data);
  } catch (error) {
    notificationError({ content: "Có lỗi xảy ra! Vui lòng thử lại sau" });
    // yield put(loginFailure(error as any));
  }
}

function* login() {
  yield takeLatest(LOGIN, doLogin);
}

export default function* userSaga() {
  yield all([login()]);
}
