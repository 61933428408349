import { applyMiddleware, createStore, Store } from "redux";
import sagaMiddlewareFactory from "redux-saga";
import { state } from "./reducers";
import sagas from "./sagas";
import storageSession from 'redux-persist/es/storage/session';
import { persistStore, persistReducer } from 'redux-persist';
const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['courseDetail']
}
const persistedReducer = persistReducer(persistConfig, state) 
const sagaMiddleware = sagaMiddlewareFactory();

const store: Store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
let persistor = persistStore(store)

sagaMiddleware.run(sagas);

export { store, persistor };
