import { GET_CONTACT, UPDATE_CONTACT } from "redux-saga/actions";
import { GET_CONTACT_SUCCESS, UPDATE_CONTACT_SUCCESS } from "./reducers";
import { Obj } from "interface/common";

export const getContact = () => ({
  type: GET_CONTACT,
});

export const getContactSuccess = (data: Obj) => ({
  type: GET_CONTACT_SUCCESS,
  payload: data,
});

export const updateContact = (data: Obj) => ({
  type: UPDATE_CONTACT,
  payload: data,
});

export const updateContactSuccess = (data: Obj) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: data,
});
