import { Action, Obj, ResponseMeta } from "interface/common";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const GET_PROFILE_DETAIL_SUCCESS = "GET_PROFILE_DETAIL_SUCCESS";

interface ProfileState {
  profiles: Obj[];
  profileDetail: null | Obj;
  success: boolean;
  meta: ResponseMeta;
}

const initState: ProfileState = {
  profiles: [],
  profileDetail: null,
  success: false,
  meta: {
    page: 1,
    size: 10,
  },
};

export const Profile = (
  state: ProfileState = initState,
  action: Action
): ProfileState => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profiles: action.payload.data,
        success: true,
        meta: {
          page: action.payload.currentPage,
          size: 10,
          total: action.payload.totalItems,
        },
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        profiles: state.profiles.map((profile) => {
          if (profile.id === action.payload.id) {
            return action.payload;
          }
          return profile;
        }),
        profileDetail: action.payload,
      };
    }

    case DELETE_PROFILE_SUCCESS: {
      return {
        ...state,
        profiles: state.profiles.filter(
          (profile) => profile.id !== action.payload
        ),
      };
    }

    case CREATE_PROFILE_SUCCESS: {
      return {
        ...state,
        profiles: [action.payload, ...state.profiles],
      };
    }

    case GET_PROFILE_DETAIL_SUCCESS: {
      return {
        ...state,
        profileDetail: action.payload,
      };
    }

    default:
      return state;
  }
};

export default Profile;
