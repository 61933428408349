import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { notificationServerError, notificationSuccess } from "utils/common";

import { Action } from "interface/common";
import {
  CREATE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  GET_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
} from "redux-saga/actions";
import {
  createBlogCategorySuccess,
  deleteBlogCategorySuccess,
  getBlogCategorySuccess,
  updateBlogCategorySuccess,
} from "./actions";

//Get blogCategory
function* doGetBlogCategory(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/categories`,
      REQUEST_METHOD.GET,
      action.payload,
      undefined,
      true
    );
    // console.log(response.data.data);
    yield response.data && put(getBlogCategorySuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getBlogCategory() {
  yield takeLatest(GET_BLOG_CATEGORY, doGetBlogCategory);
}

//Update blogCategory
function* doUpdateBlogCategory(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/category`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(updateBlogCategorySuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* updateBlogCategory() {
  yield takeLatest(UPDATE_BLOG_CATEGORY, doUpdateBlogCategory);
}
//Delete blogCategory
function* doDeleteBlogCategory(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/category/${action.payload}`,
      REQUEST_METHOD.DELETE,
      undefined,
      undefined,
      true
    );
    yield put(deleteBlogCategorySuccess(action.payload));
  } catch (error) {
    notificationServerError((error as any).response.data.message);
  }
}

function* deleteBlogCategory() {
  yield takeLatest(DELETE_BLOG_CATEGORY, doDeleteBlogCategory);
}
//create blogCategory
function* doCreateBlogCategory(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/category`,
      REQUEST_METHOD.POST,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(createBlogCategorySuccess(response.data));
    notificationSuccess({ content: "Tạo danh mục thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* createBlogCategory() {
  yield takeLatest(CREATE_BLOG_CATEGORY, doCreateBlogCategory);
}
export default function* blogCategory() {
  yield all([
    getBlogCategory(),
    updateBlogCategory(),
    deleteBlogCategory(),
    createBlogCategory(),
  ]);
}
