import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { notificationServerError, notificationSuccess } from "utils/common";
import { Action } from "interface/common";
import { GET_CONTACT, UPDATE_CONTACT } from "redux-saga/actions";
import { getContactSuccess, updateContactSuccess } from "./actions";

//Get profile
function* doGetContact(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/contact`,
      REQUEST_METHOD.GET
    );
    yield put(getContactSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getContact() {
  yield takeLatest(GET_CONTACT, doGetContact);
}

function* doUpdateContact(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/contact`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(updateContactSuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* updateContact() {
  yield takeLatest(UPDATE_CONTACT, doUpdateContact);
}

export default function* contactSagas() {
  yield all([getContact(), updateContact()]);
}
