import { Suspense, useContext, lazy } from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { SCREEN_ROUTES } from "./constants";
import { AuthContext } from "components/AuthProvider";
import Loader from "components/Loader";
import WebShell from "components/CMS/WebShell";
import Alert from "components/AlertTemplate";
import ScrollTop from "components/ScrollTop";
import { AdminMenu } from "interface/sidebar";
import "./App.scss";

const publicRoutes = [
  {
    route: SCREEN_ROUTES.login,
    component: lazy(
      () => import(/* webpackChunkName: "Login" */ "screens/Login")
    ),
  },
  {
    route: SCREEN_ROUTES.tokenHandler,
    component: lazy(
      () =>
        import(/* webpackChunkName: "TokenHandler" */ "screens/TokenHandler")
    ),
  },
  {
    route: "/404",
    component: lazy(
      () => import(/* webpackChunkName: "404" */ "screens/NotFound")
    ),
    exact: true,
  },
];

const adminRoutes = [
  {
    route: "/",
    component: lazy(
      () => import(/* webpackChunkName: "Blog" */ "screens/AdminCMS/Blog")
    ),
    exact: true,
  },
  {
    route: SCREEN_ROUTES.admin.blog,
    component: lazy(
      () => import(/* webpackChunkName: "Blog" */ "screens/AdminCMS/Blog")
    ),
  },
  {
    route: SCREEN_ROUTES.admin.yesnews,
    component: lazy(
      () => import(/* webpackChunkName: "YesNews" */ "screens/AdminCMS/YesNews")
    ),
  },
  {
    route: SCREEN_ROUTES.admin.profile,
    component: lazy(
      () => import(/* webpackChunkName: "Profile" */ "screens/AdminCMS/Profile")
    ),
  },
  {
    route: SCREEN_ROUTES.admin.research,
    component: lazy(
      () =>
        import(/* webpackChunkName: "Research" */ "screens/AdminCMS/Reseacrh")
    ),
  },
  {
    route: SCREEN_ROUTES.admin.settings,
    component: lazy(
      () =>
        import(/* webpackChunkName: "Settings" */ "screens/AdminCMS/Settings")
    ),
  },
];

interface PrivateRouteProps extends RouteProps {}

function PrivateRoute(props: PrivateRouteProps) {
  const { isLogged } = useContext(AuthContext);
  if (!isLogged) {
    return <Redirect to={"/login"} />;
  }
  return <Route {...props}>{props.children}</Route>;
}

function App() {
  return (
    <>
      <Switch>
        {adminRoutes.map((route, index) => {
          return (
            <PrivateRoute exact={route.exact} path={route.route} key={index}>
              <WebShell menu={AdminMenu}>
                <Suspense fallback={<Loader />}>
                  <ScrollTop>{<route.component />}</ScrollTop>
                  <Alert />
                </Suspense>
              </WebShell>
            </PrivateRoute>
          );
        })}
        {publicRoutes.map((route, index) => {
          return (
            <Route exact={route.exact} path={route.route} key={index}>
              <Suspense fallback={<Loader />}>
                <ScrollTop>{<route.component />}</ScrollTop>
              </Suspense>
            </Route>
          );
        })}
        <Redirect to="/404" />
      </Switch>
    </>
  );
}

export default App;
