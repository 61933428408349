import React, { useState } from "react";
import { getToken } from "utils/httpClient";

interface Auth {
    isLogged?: boolean;

    signIn?: (token: string) => void;
    signOut?: () => void;
}

export const AuthContext = React.createContext<Auth>({});

const isValidToken = () => {
    /**
     *  Using the local storage code....
     */
    const token = getToken();
    // JWT decode & check token validity & expiration.
    if (token) return true;
    return false;
};

interface AuthProviderProps {
    children?: JSX.Element;
}

const AuthProvider = (props: AuthProviderProps) => {
    const [isLogged, setIsLogged] = useState(isValidToken());
    const signIn = (token: string) => {
        localStorage.setItem("token", token);
        setIsLogged(true);
    };

    const signOut = () => {
        localStorage.clear();
        setIsLogged(false);
    };

    return (
        <AuthContext.Provider value={{ isLogged, signIn, signOut }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
