import { combineReducers } from "redux";
import Blog from "./Blog/reducers";
import BlogCategory from "./BlogCategory/reducers";
import News from "./News/reducers";
import Profile from "./Profile/reducers";
import Theme from "./Theme/reducer";
import Human from "./Human/reducers";
import ContactReducer from "./Contact/reducers";

export const state = combineReducers({
  blog: Blog,
  blogCategory: BlogCategory,
  news: News,
  profile: Profile,
  theme: Theme,
  human: Human,
  contact: ContactReducer,
});

export type State = ReturnType<typeof state>;
