import { Obj } from "interface/common";
import {
  CREATE_PROFILE,
  DELETE_PROFILE,
  GET_PROFILES,
  GET_PROFILE_DETAIL,
  UPDATE_PROFILE,
} from "redux-saga/actions";
import {
  CREATE_PROFILE_SUCCESS,
  DELETE_PROFILE_SUCCESS,
  GET_PROFILE_DETAIL_SUCCESS,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
} from "./reducers";

export const getProfile = (data: Obj) => {
  return {
    type: GET_PROFILES,
    payload: data,
  };
};

export const getProfileSuccess = (data: Obj) => ({
  type: GET_PROFILE_SUCCESS,
  payload: data,
});

export const updateProfile = (data: { id: number; data: Obj }) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const updateProfileSuccess = (data: Obj) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: data,
});

export const deleteProfile = (data: number) => ({
  type: DELETE_PROFILE,
  payload: data,
});

export const deleteProfileSuccess = (data: number) => ({
  type: DELETE_PROFILE_SUCCESS,
  payload: data,
});

export const createProfile = (data: Obj) => ({
  type: CREATE_PROFILE,
  payload: data,
});

export const createProfileSuccess = (data: Obj) => ({
  type: CREATE_PROFILE_SUCCESS,
  payload: data,
});

export const getProfileDetail = (data: number) => {
  return {
    type: GET_PROFILE_DETAIL,
    payload: data,
  };
};

export const getProfileDetailSuccess = (data: Obj) => ({
  type: GET_PROFILE_DETAIL_SUCCESS,
  payload: data,
});
