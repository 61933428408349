import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { Obj } from "interface/common";
import {
  MEDIA_TYPE,
  notificationError,
  notificationServerError,
} from "./common";

export const BASE_URL = "https://api.yesneu.com";
// export const BASE_URL = "http://localhost:3000";
// export const BASE_URL = "http://192.168.1.9:3000";

export enum REQUEST_METHOD {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

export const configAxios = (
  url: string,
  method: REQUEST_METHOD,
  params?: Obj,
  baseURL?: string,
  tokenRequired?: boolean,
  data?: Obj
) => {
  return tokenRequired
    ? {
        url,
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        params,
        paramsSerializer: (params: Obj) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
        baseURL: baseURL ? baseURL : BASE_URL,
        data,
      }
    : {
        url,
        method,
        headers: {
          "Content-Type": "application/json",
        },
        params,
        paramsSerializer: (params: Obj) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
        baseURL: baseURL ? baseURL : BASE_URL,
        data,
      };
};

export async function query(
  url: string,
  method: REQUEST_METHOD,
  params?: Obj,
  baseURL?: string,
  tokenRequired?: boolean,
  data?: any
) {
  const config = configAxios(url, method, params, baseURL, tokenRequired, data);

  try {
    const response: AxiosResponse = await axios(config);
    return response;
  } catch (error) {
    console.log(error);

    if ((error as any).response.status === 401) {
      window.localStorage.clear();
      window.location.replace("/login");
    }
    throw error;
  }
}

export const uploadFile = async (
  type: MEDIA_TYPE,
  formData: FormData | string,
  handleProgress?: (percent: number) => void
) => {
  try {
    const res = await axios.post(`${BASE_URL}/api/file`, formData, {
      onUploadProgress: (progressEvent) =>
        handleProgress &&
        handleProgress(
          Math.round(
            ((progressEvent.loaded as number) * 100) / progressEvent.total
          ) as number
        ),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    const data = res.data;
    return data;
  } catch (error) {
    notificationError({ content: "Có lỗi xảy ra! Vui lòng thử lại sau" });
    throw error;
  }
};

export const uploadTextDocument = async (type: MEDIA_TYPE, text: string) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/teaching/files/${
        type === MEDIA_TYPE.YOUTUBE ? "urls" : "texts"
      }`,
      type === MEDIA_TYPE.YOUTUBE
        ? {
            url: text,
            type: "YOUTUBE",
          }
        : {
            content: text,
            type: "TEXT",
          },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    const data = res.data;
    return data;
  } catch (error) {
    notificationServerError((error as any).response.data.meta.code);
    throw error;
  }
};

export async function exportExcel(url: string, query?: Obj) {
  try {
    const response = await axios.get(`${BASE_URL}/${url}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: query,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function downloadFile(url: string) {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "file"); //or any other extension
  link.click();
}

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const saveToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const replaceParamsById = (
  url: string,
  paramKey: string,
  id: string
) => {
  return url.replace(paramKey, id);
};
