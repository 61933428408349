import React, { useState } from 'react';
export enum  AlertStatus {
    None = 'NONE',
    Success = 'SUCCESS',
    Error = 'ERROR'
}
const AlertContext = React.createContext({});
AlertContext.displayName = 'AlertContext';

const AlertProvider : React.FC<{}> = ({ children}) => {
  const [alert, setAlert] = useState(AlertStatus.None);
  const [alertText, setAlertText] = useState('');

  return (
    <AlertContext.Provider
      value={{
        alert: alert,
        alertText: alertText,
        success: (text: string, timeout: number) => {
          setAlertText(text);
          setAlert(AlertStatus.Success);
          setTimeout(() => {
            setAlert(AlertStatus.None);
          }, timeout * 1000 || 4000)

        },
        error: (text: string, timeout: number) => {
          setAlertText(text);
          setAlert(AlertStatus.Error);
          setTimeout(() => {
            setAlert(AlertStatus.None);
          }, timeout * 1000 || 4000)
        },
        clear: () => (setAlert(AlertStatus.None)),
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider, AlertContext };