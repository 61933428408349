import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { notificationServerError, notificationSuccess } from "utils/common";

import { Action } from "interface/common";
import { GET_THEME, UPDATE_THEME } from "redux-saga/actions";
import { getThemeSuccess, updateThemeSuccess } from "./actions";

//Get profile
function* doGetTheme(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/theme`,
      REQUEST_METHOD.GET
    );
    yield put(getThemeSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getTheme() {
  yield takeLatest(GET_THEME, doGetTheme);
}

function* doUpdateTheme(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/theme`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(updateThemeSuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành công" });
  } catch (error) {
    notificationServerError(error);
  }
}

function* updateTheme() {
  yield takeLatest(UPDATE_THEME, doUpdateTheme);
}

export default function* themeSagas() {
  yield all([getTheme(), updateTheme()]);
}
