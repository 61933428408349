import { Obj } from "interface/common";
import {
  CHANGE_NEWS_HIGHLIGHT,
  CREATE_NEWS,
  DELETE_NEWS,
  GET_NEWS,
  GET_NEWS_DETAIL,
  PUBLISH_NEWS,
  UPDATE_NEWS,
} from "redux-saga/actions";
import {
  CHANGE_NEWS_HIGHLIGHT_SUCCESS,
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_SUCCESS,
  GET_NEWS_DETAIL_SUCCESS,
  GET_NEWS_SUCCESS,
  PUBLISH_NEWS_SUCCESS,
  UPDATE_NEWS_SUCCESS,
} from "./reducers";

export const getNews = (data: Obj) => {
  return {
    type: GET_NEWS,
    payload: data,
  };
};

export const getNewsSuccess = (data: Obj) => ({
  type: GET_NEWS_SUCCESS,
  payload: data,
});

export const updateNews = (
  data: { id: number; data: Obj },
  cb?: () => void
) => ({
  type: UPDATE_NEWS,
  payload: data,
  handleSuccess: cb,
});

export const updateNewsSuccess = (data: Obj) => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: data,
});

export const deleteNews = (data: number) => ({
  type: DELETE_NEWS,
  payload: data,
});

export const deleteNewsSuccess = (data: number) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: data,
});

export const createNews = (data: Obj, cb?: () => void) => ({
  type: CREATE_NEWS,
  payload: data,
  handleSuccess: cb,
});

export const createNewsSuccess = (data: Obj) => ({
  type: CREATE_NEWS_SUCCESS,
  payload: data,
});

export const getNewsDetail = (data: number) => {
  return {
    type: GET_NEWS_DETAIL,
    payload: data,
  };
};

export const getNewsDetailSuccess = (data: Obj) => ({
  type: GET_NEWS_DETAIL_SUCCESS,
  payload: data,
});

export const publishNews = (data: Obj) => {
  return {
    type: PUBLISH_NEWS,
    payload: data,
  };
};

export const publishNewsSuccess = (data: Obj) => ({
  type: PUBLISH_NEWS_SUCCESS,
  payload: data,
});

export const changeNewsHightlight = (data: Obj) => {
  return {
    type: CHANGE_NEWS_HIGHLIGHT,
    payload: data,
  };
};

export const changeNewsHightlightSuccess = (data: Obj) => ({
  type: CHANGE_NEWS_HIGHLIGHT_SUCCESS,
  payload: data,
});
