import { Obj } from "interface/common";

export const SCREEN_ROUTES = {
  root: "/",
  admin: {
    blog: "/admin/blog",
    yesnews: "/admin/yesnews",
    profile: "/admin/profile",
    research: "/admin/research",
    settings: "/admin/settings",
  },
  login: "/login",
  tokenHandler: "/token-handle",
};

export const ERROR_CODE: Obj = {
  "BASE-LIVE-4143": "Loại khóa học đã tồn tại",
  "BASE-LIVE-4228": "Đường dẫn youtube không hợp lệ",
  "BASE-LIVE-4229": "Khóa học đã có người đăng ký, không thể xóa",
  "BASE-LIVE-4224":
    "Câu hỏi này đang thuộc ít nhất 1 đề thi, bạn không thể xóa",
  "BASE-LIVE-4352": "Bạn đã đánh giá khóa học này rồi",
};

export const SUCCESS_CODE: Obj = {
  "BASE-LIVE-200": "Thành công",
};
