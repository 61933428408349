import { Action, Obj, ResponseMeta } from "interface/common";

export const GET_BLOG_CATEGORY_SUCCESS = "GET_BLOG_CATEGORY_SUCCESS";
export const UPDATE_BLOG_CATEGORY_SUCCESS = "UPDATE_BLOG_CATEGORY_SUCCESS";
export const DELETE_BLOG_CATEGORY_SUCCESS = "DELETE_BLOG_CATEGORY_SUCCESS";
export const CREATE_BLOG_CATEGORY_SUCCESS = "CREATE_BLOG_CATEGORY_SUCCESS";

interface BlogCategoryState {
  blogCategoriess: Obj[];
  blogCategoriesDetail: null | Obj;
  success: boolean;
  meta: ResponseMeta;
}

const initState: BlogCategoryState = {
  blogCategoriess: [],
  blogCategoriesDetail: null,
  success: false,
  meta: {
    page: 1,
    size: 10,
  },
};

export const BlogCategory = (
  state: BlogCategoryState = initState,
  action: Action
): BlogCategoryState => {
  switch (action.type) {
    case GET_BLOG_CATEGORY_SUCCESS: {
      return {
        ...state,
        blogCategoriess: action.payload,
        success: true,
      };
    }
    case UPDATE_BLOG_CATEGORY_SUCCESS: {
      return {
        ...state,
        blogCategoriess: state.blogCategoriess.map((blogCategories) => {
          if (blogCategories.id === action.payload.id) {
            return action.payload;
          }
          return blogCategories;
        }),
      };
    }

    case DELETE_BLOG_CATEGORY_SUCCESS: {
      return {
        ...state,
        blogCategoriess: state.blogCategoriess.filter(
          (blogCategories) => blogCategories.id !== action.payload
        ),
      };
    }

    case CREATE_BLOG_CATEGORY_SUCCESS: {
      return {
        ...state,
        blogCategoriess: [action.payload, ...state.blogCategoriess],
      };
    }
    default:
      return state;
  }
};

export default BlogCategory;
