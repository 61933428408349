import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { AlertProvider } from "components/AlertContext";
import AuthProvider from "components/AuthProvider";
import { store, persistor } from "redux-saga/store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import momentLocale from "config/moment_locale.json";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "antd/dist/antd.css";
import "scss/styles.scss";
import "./index.css";

toast.configure();
moment.locale("vn", momentLocale.vn);

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <AuthProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <AlertProvider>
                        <DndProvider backend={HTML5Backend}>
                            <App />
                        </DndProvider>
                    </AlertProvider>
                </PersistGate>
            </AuthProvider>
        </Provider>
    </Router>,
    document.getElementById("root")
);
