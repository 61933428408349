import { CheckCircleFilled } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { AlertContext } from "components/AlertContext";
import { useContext } from "react";
import "./styles.scss";
const AlertTemplate = () => {
    const alert = useContext(AlertContext);
    return (
        <>
            {(alert as any).alert !== "NONE" && (
                <Alert
                    message={(alert as any).alertText as string}
                    type="info"
                    closable={false}
                    className="AlertCustom"
                    icon={<CheckCircleFilled />}
                    showIcon={true}
                    action={
                        <Button
                            size="small"
                            type="text"
                            onClick={() => {
                                (alert as any).clear();
                            }}
                        >
                            ĐÓNG
                        </Button>
                    }
                />
            )}
        </>
    );
};
export default AlertTemplate;
