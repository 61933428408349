import { Spin } from "antd";
import "./styles.scss";

const Loader = () => {
    return (
        <div className="Loader">
            <Spin />
        </div>
    );
};

export default Loader;
