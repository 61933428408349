import { ERROR_CODE } from "../constants";
import { Obj } from "interface/common";
import { toast } from "react-toastify";

export enum MEDIA_TYPE {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  PDF = "PDF",
  EXCEL = "EXCEL",
  YOUTUBE = "YOUTUBE",
  TEXT_DOCUMENT = "TEXT_DOCUMENT",
}

export enum EVENT_COURSE_TYPE {
  ALL = "ALL",
  FREE = "FREE",
  PAY_FEES = "PAY_FEES",
}

export enum EVENT_COURSE_STATUS {
  ALL = "",
  ENDED = "ENDED",
  HAPPENING = "HAPPENING",
  NOT_HAPPEN = "NOT_HAPPEN",
}

export enum FIELD_SORT {
  NUMBER_PARTICIPANTS = "numberParticipants",
  CREATED_AT = "createdAt",
  PUBLISH_TIME = "publishTime",
  AVERAGE_RATE = "averageRate",
}
export enum DIRECTION_SORT {
  ASC = "ASC",
  DESC = "DESC",
}
export enum TYPE_SOLD {
  ALL = "ALL",
  NOT_SOLD = "NOT_SOLD",
  SOLD = "SOLD",
}

export const formatNumber = (
  value?: number,
  digit?: number,
  offsetRate?: number,
  toFixed?: boolean,
  failoverValue = "0"
) => {
  if (value == null || isNaN(value)) {
    return failoverValue;
  }

  if (offsetRate != null) {
    value = value / offsetRate;
  }

  let tempValueString = value.toString();
  let prefix = "";

  if (tempValueString[0] === "-") {
    prefix = "-";
    tempValueString = tempValueString.substring(1, tempValueString.length);
  }

  try {
    const tempValue = Number(tempValueString);
    let fractionDigit = 0;
    if (digit != null) {
      fractionDigit = digit;
    }
    if (fractionDigit > 0) {
      const temp = +`${Math.round(
        Number(`${Number(tempValue.toString())}e+${fractionDigit}`)
      )}e-${fractionDigit}`;
      let fractionString = "";
      let i = "";
      if (toFixed === true) {
        i = temp.toFixed(fractionDigit);
        fractionString = i.substring(i.indexOf("."), i.length);
        i = i.substring(0, i.indexOf("."));
      } else {
        i = temp.toString();
        if (temp.toString().indexOf(".") > 1) {
          fractionString = temp
            .toString()
            .substring(temp.toString().indexOf("."), temp.toString().length);
          i = temp.toString().substring(0, temp.toString().indexOf("."));
        }
      }
      return prefix + i.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + fractionString;
    } else {
      const temp = +`${Math.round(
        Number(`${Number(tempValue.toString())}e+${fractionDigit}`)
      )}e-${fractionDigit}`;
      const i = temp.toString();
      return prefix + i.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } catch {
    return "";
  }
};

export function formatNumberK(num?: number | null) {
  if (num === undefined || num === null) {
    return 0;
  }
  if (num > 999 && num < 1000000) {
    const digit = (
      (num - 1000 * parseInt((num / 1000).toFixed(0))) /
      100
    ).toFixed(0);
    return digit !== "0"
      ? (num / 1000).toFixed(0) + "k" + digit
      : (num / 1000).toFixed(0) + "k"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    const digit = (
      (num - 1000000 * parseInt((num / 1000000).toFixed(0))) /
      100000
    ).toFixed(0);
    return digit !== "0"
      ? (num / 1000000).toFixed(0) + "m" + digit
      : (num / 1000000).toFixed(0) + "m"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  }
}

export const notificationError = (params: Obj) => {
  toast.error(params.content, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const notificationSuccess = (params: Obj) => {
  toast.success(params.content, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const notificationServerError = (message: any) => {
  // if ((error as any).response) {
  //   const code = error.response.data.meta.code;
  //   if (code && code in ERROR_CODE)
  //     notificationError({ content: ERROR_CODE[code] });
  //   else {
  //     notificationError({
  //       content: "Có lỗi xảy ra! Vui lòng thử lại sau",
  //     });
  //   }
  // } else {
  notificationError({
    content: message ?? "Có lỗi xảy ra! Vui lòng thử lại sau",
  });
  // }
};

export function isDecimal(number: number) {
  if (number % 1 !== 0) {
    return true;
  }
  return false;
}

export const loadVideo = (file: File) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function () {
        reject("Invalid video. Please select a video file.");
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export const getVideoLength = async (file: File) => {
  try {
    const video = await loadVideo(file);
    return (video as Obj).duration as number;
  } catch (error) {
    throw error;
  }
};

export const getYoutubeThumbnail = (url: string) => {
  if (url === null) {
    return "";
  }
  const results = url.match("[\\?&]v=([^&#]*)");
  const video = results === null ? url : results[1];

  return "http://img.youtube.com/vi/" + video + "/0.jpg";
};

export const getTextContentOfEditor = (data: string) => {
  const el = document.createElement("div");
  el.innerHTML = data;
  return el.textContent;
};

export const getFileNameFromServerUrl = (url: string) => {
  return url.split("_").pop();
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let params: Obj = {};
  urlParams.forEach((value, key, parent) => {
    params[key] = value;
  });
  return params;
};
