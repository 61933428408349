export const LOGIN = "LOGIN";

export const GET_BLOG = "GET_BLOG";
export const CREATE_BLOG = "CREATE_BLOG";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const DELETE_BLOG = " DELETE_BLOG";

export const GET_BLOG_CATEGORY = "GET_BLOG_CATEGORY";
export const CREATE_BLOG_CATEGORY = "CREATE_BLOG_CATEGORY";
export const UPDATE_BLOG_CATEGORY = "UPDATE_BLOG_CATEGORY";
export const DELETE_BLOG_CATEGORY = " DELETE_BLOG_CATEGORY";
export const GET_BLOG_DETAIL = "GET_BLOG_DETAIL";
export const PUBLISH_BLOG = "PUBLISH_BLOG";
export const HIGHLIGHT_BLOG = "HIGHLIGHT_BLOG";

export const GET_NEWS = "GET_NEWS";
export const CREATE_NEWS = "CREATE_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = " DELETE_NEWS";

export const GET_NEWS_DETAIL = "GET_NEWS_DETAIL";
export const PUBLISH_NEWS = "PUBLISH_NEWS";
export const CHANGE_NEWS_HIGHLIGHT = "CHANGE_NEWS_HIGHLIGHT";

export const CREATE_PROFILE = "CREATE_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE_DETAIL = "GET_PROFILE_DETAIL";

export const GET_THEME = "GET_THEME";
export const UPDATE_THEME = "UPDATE_THEME";

export const ADD_HUMAN = "ADD_HUMAN";
export const UPDATE_HUMAN = "UPDATE_HUMAN";
export const GET_HUMAN = "GET_HUMAN";
export const DELETE_HUMAN = "DELETE_HUMAN";

export const GET_CONTACT = "GET_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
