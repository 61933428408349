import { Action } from "interface/common";

export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";

interface ContactState {
  fanpage?: string;
  address?: string;
  hotline?: string;
  email?: string;
}

const initState: ContactState = {};

export const ContactReducer = (
  state: ContactState = initState,
  action: Action
): ContactState => {
  switch (action.type) {
    case GET_CONTACT_SUCCESS: {
      return action.payload;
    }

    case UPDATE_CONTACT_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default ContactReducer;
