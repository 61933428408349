import { Obj } from "interface/common";
import {
  CREATE_BLOG,
  DELETE_BLOG,
  GET_BLOG,
  GET_BLOG_DETAIL,
  HIGHLIGHT_BLOG,
  PUBLISH_BLOG,
  UPDATE_BLOG,
} from "redux-saga/actions";
import {
  CREATE_BLOG_SUCCESS,
  DELETE_BLOG_SUCCESS,
  GET_BLOG_DETAIL_SUCCESS,
  GET_BLOG_SUCCESS,
  HIGHLIGHT_BLOG_SUCCESS,
  PUBLISH_BLOG_SUCCESS,
  UPDATE_BLOG_SUCCESS,
} from "./reducers";

export const getBlog = (data: Obj) => {
  return {
    type: GET_BLOG,
    payload: data,
  };
};

export const getBlogSuccess = (data: Obj) => ({
  type: GET_BLOG_SUCCESS,
  payload: data,
});

export const updateBlog = (data: { id: number; data: Obj }) => ({
  type: UPDATE_BLOG,
  payload: data,
});

export const updateBlogSuccess = (data: Obj) => ({
  type: UPDATE_BLOG_SUCCESS,
  payload: data,
});

export const deleteBlog = (data: number) => ({
  type: DELETE_BLOG,
  payload: data,
});

export const deleteBlogSuccess = (data: number) => ({
  type: DELETE_BLOG_SUCCESS,
  payload: data,
});

export const createBlog = (data: Obj) => ({
  type: CREATE_BLOG,
  payload: data,
});

export const createBlogSuccess = (data: Obj) => ({
  type: CREATE_BLOG_SUCCESS,
  payload: data,
});

export const getBlogDetail = (data: number) => {
  return {
    type: GET_BLOG_DETAIL,
    payload: data,
  };
};

export const getBlogDetailSuccess = (data: Obj) => ({
  type: GET_BLOG_DETAIL_SUCCESS,
  payload: data,
});

export const publishBlog = (data: Obj) => {
  return {
    type: PUBLISH_BLOG,
    payload: data,
  };
};

export const publishBlogSuccess = (data: Obj) => ({
  type: PUBLISH_BLOG_SUCCESS,
  payload: data,
});

export const highlightBlog = (data: Obj) => ({
  type: HIGHLIGHT_BLOG,
  payload: data,
});

export const highlightBlogSuccess = (data: Obj) => ({
  type: HIGHLIGHT_BLOG_SUCCESS,
  payload: data,
});
