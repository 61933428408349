import { Action, Obj, ResponseMeta } from "interface/common";

export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS";
export const GET_NEWS_DETAIL_SUCCESS = "GET_NEWS_DETAIL_SUCCESS";
export const PUBLISH_NEWS_SUCCESS = "PUBLISH_NEWS_SUCCESS";
export const UNDO_PUBLISH_NEWS_SUCCESS = "UNDO_PUBLISH_NEWS_SUCCESS";
export const CHANGE_NEWS_HIGHLIGHT_SUCCESS = "CHANGE_NEWS_HIGHLIGHT_SUCCESS";

interface NewsState {
  newss: Obj[];
  newsDetail: null | Obj;
  success: boolean;
  meta: ResponseMeta;
}

const initState: NewsState = {
  newss: [],
  newsDetail: null,
  success: false,
  meta: {
    page: 1,
    size: 10,
    total: 0,
  },
};

export const News = (
  state: NewsState = initState,
  action: Action
): NewsState => {
  switch (action.type) {
    case GET_NEWS_SUCCESS: {
      return {
        ...state,
        newss: action.payload.data,
        success: true,
        meta: {
          page: action.payload.currentPage,
          size: 10,
          total: action.payload.totalItems,
        },
      };
    }
    case UPDATE_NEWS_SUCCESS: {
      return {
        ...state,
        newss: state.newss.map((news) => {
          if (news.id === action.payload.id) {
            return action.payload;
          }
          return news;
        }),
        newsDetail: action.payload,
      };
    }

    case DELETE_NEWS_SUCCESS: {
      return {
        ...state,
        newss: state.newss.filter((news) => news.id !== action.payload),
      };
    }

    case CREATE_NEWS_SUCCESS: {
      return {
        ...state,
        newss: [action.payload, ...state.newss],
      };
    }

    case GET_NEWS_DETAIL_SUCCESS: {
      return {
        ...state,
        newsDetail: action.payload,
      };
    }

    case PUBLISH_NEWS_SUCCESS: {
      return {
        ...state,
        newss: state.newss.map((news) => {
          if (news.id === action.payload.id) {
            news.status = action.payload.status;
          }
          return news;
        }),
      };
    }

    case CHANGE_NEWS_HIGHLIGHT_SUCCESS: {
      return {
        ...state,
        newss: state.newss.map((news) => {
          if (news.id === action.payload.id) {
            news.highlight = action.payload.highlight;
          }
          return news;
        }),
      };
    }
    default:
      return state;
  }
};

export default News;
