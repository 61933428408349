import { Obj } from "interface/common";
import { GET_THEME, UPDATE_THEME } from "redux-saga/actions";
import { GET_THEME_SUCCESS, UPDATE_THEME_SUCCESS } from "./reducer";

export const getTheme = () => {
  return {
    type: GET_THEME,
  };
};

export const getThemeSuccess = (data: Obj) => ({
  type: GET_THEME_SUCCESS,
  payload: data,
});

export const updateTheme = (data: Obj) => ({
  type: UPDATE_THEME,
  payload: data,
});

export const updateThemeSuccess = (data: Obj) => ({
  type: UPDATE_THEME_SUCCESS,
  payload: data,
});
