import { Obj } from "interface/common";
import {
  ADD_HUMAN,
  DELETE_HUMAN,
  GET_HUMAN,
  UPDATE_HUMAN,
} from "redux-saga/actions";
import {
  ADD_HUMAN_SUCCESS,
  DELETE_HUMAN_SUCCESS,
  GET_HUMAN_SUCCESS,
  UPDATE_HUMAN_SUCCESS,
} from "./reducers";

export const getHuman = () => {
  return {
    type: GET_HUMAN,
  };
};

export const getHumanSuccess = (data: Obj) => ({
  type: GET_HUMAN_SUCCESS,
  payload: data,
});

export const addHuman = (data: Obj) => ({
  type: ADD_HUMAN,
  payload: data,
});

export const addHumanSuccess = (data: Obj) => ({
  type: ADD_HUMAN_SUCCESS,
  payload: data,
});

export const updateHuman = (data: Obj) => ({
  type: UPDATE_HUMAN,
  payload: data,
});

export const updateHumanSuccess = (data: Obj) => ({
  type: UPDATE_HUMAN_SUCCESS,
  payload: data,
});

export const deleteHuman = (id: number) => ({
  type: DELETE_HUMAN,
  payload: id,
});

export const deleteHumanSuccess = (id: number) => ({
  type: DELETE_HUMAN_SUCCESS,
  payload: id,
});
