import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { notificationServerError, notificationSuccess } from "utils/common";

import { Action } from "interface/common";
import {
  CREATE_PROFILE,
  DELETE_PROFILE,
  GET_PROFILES,
  GET_PROFILE_DETAIL,
  UPDATE_PROFILE,
} from "redux-saga/actions";
import {
  createProfileSuccess,
  deleteProfileSuccess,
  getProfileDetailSuccess,
  getProfileSuccess,
  updateProfileSuccess,
} from "./actions";

//Get profile
function* doGetProfile(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/profiles`,
      REQUEST_METHOD.GET,
      action.payload,
      undefined,
      true
    );
    yield put(getProfileSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getProfile() {
  yield takeLatest(GET_PROFILES, doGetProfile);
}

//get profile detail
function* doGetProfileDetail(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/profiles/${action.payload}`,
      REQUEST_METHOD.GET,
      undefined,
      undefined,
      true
    );

    yield put(getProfileDetailSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getProfileDetail() {
  yield takeLatest(GET_PROFILE_DETAIL, doGetProfileDetail);
}

//Update profile
function* doUpdateProfile(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/profiles`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    console.log(response.data);
    yield put(updateProfileSuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành viên thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* updateProfile() {
  yield takeLatest(UPDATE_PROFILE, doUpdateProfile);
}
//Delete profile
function* doDeleteProfile(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/profiles/${action.payload}`,
      REQUEST_METHOD.DELETE,
      undefined,
      undefined,
      true
    );
    yield put(deleteProfileSuccess(action.payload));
  } catch (error) {
    notificationServerError((error as any).response.data.meta.code);
  }
}

function* deleteProfile() {
  yield takeLatest(DELETE_PROFILE, doDeleteProfile);
}
//create profile
function* doCreateProfile(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/profiles`,
      REQUEST_METHOD.POST,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(createProfileSuccess(response.data));
    notificationSuccess({ content: "Thêm thành viên thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* createProfile() {
  yield takeLatest(CREATE_PROFILE, doCreateProfile);
}

export default function* profileSagas() {
  yield all([
    getProfile(),
    updateProfile(),
    deleteProfile(),
    createProfile(),
    getProfileDetail(),
  ]);
}
