import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import {
  notificationError,
  notificationServerError,
  notificationSuccess,
} from "utils/common";

import { Action } from "interface/common";
import {
  CREATE_BLOG,
  DELETE_BLOG,
  GET_BLOG,
  GET_BLOG_DETAIL,
  HIGHLIGHT_BLOG,
  PUBLISH_BLOG,
  UPDATE_BLOG,
} from "redux-saga/actions";
import {
  createBlogSuccess,
  deleteBlogSuccess,
  getBlogDetailSuccess,
  getBlogSuccess,
  highlightBlogSuccess,
  publishBlogSuccess,
  updateBlogSuccess,
} from "./actions";

//Get blog
function* doGetBlog(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/posts/${action.payload.type}`,
      REQUEST_METHOD.GET,
      action.payload,
      undefined,
      true
    );
    yield put(getBlogSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getBlog() {
  yield takeLatest(GET_BLOG, doGetBlog);
}

//get blog detail
function* doGetBlogDetail(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/post/${action.payload}`,
      REQUEST_METHOD.GET,
      undefined,
      undefined,
      true
    );

    yield put(getBlogDetailSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getBlogDetail() {
  yield takeLatest(GET_BLOG_DETAIL, doGetBlogDetail);
}

//Update blog
function* doUpdateBlog(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/posts`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(updateBlogSuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* updateBlog() {
  yield takeLatest(UPDATE_BLOG, doUpdateBlog);
}
//Delete blog
function* doDeleteBlog(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/posts/${action.payload}`,
      REQUEST_METHOD.DELETE,
      undefined,
      undefined,
      true
    );
    yield put(deleteBlogSuccess(action.payload));
  } catch (error) {
    notificationServerError((error as any).response.data.meta.code);
  }
}

function* deleteBlog() {
  yield takeLatest(DELETE_BLOG, doDeleteBlog);
}
//create blog
function* doCreateBlog(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/posts`,
      REQUEST_METHOD.POST,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(createBlogSuccess(response.data));
    notificationSuccess({ content: "Tạo bài viết thành công" });
  } catch (error) {
    // notificationError({content:})
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* createBlog() {
  yield takeLatest(CREATE_BLOG, doCreateBlog);
}

//create blog
function* doPublishBlog(action: Action) {
  try {
    yield query(
      `/api/posts/${action.payload.id}/status`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      {
        status: action.payload.status,
      }
    );
    yield put(publishBlogSuccess(action.payload));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* publishBlog() {
  yield takeLatest(PUBLISH_BLOG, doPublishBlog);
}

function* doHighlightBlog(action: Action) {
  try {
    yield query(
      `/api/posts/${action.payload.id}/highlight`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      {
        highlight: action.payload.highlight,
      }
    );
    yield put(highlightBlogSuccess(action.payload));
  } catch (error) {
    console.log(error);
    notificationError({ content: "Có lỗi xảy ra" });
  }
}

function* highlightBlog() {
  yield takeLatest(HIGHLIGHT_BLOG, doHighlightBlog);
}

export default function* blogSagas() {
  yield all([
    getBlog(),
    updateBlog(),
    deleteBlog(),
    createBlog(),
    getBlogDetail(),
    publishBlog(),
    highlightBlog(),
  ]);
}
