import { AxiosResponse } from "axios";
import { put, all, takeLatest } from "redux-saga/effects";
import { query, REQUEST_METHOD } from "utils/httpClient";
import { notificationServerError, notificationSuccess } from "utils/common";
import { Action } from "interface/common";
import {
  ADD_HUMAN,
  DELETE_HUMAN,
  GET_HUMAN,
  UPDATE_HUMAN,
} from "redux-saga/actions";
import {
  addHumanSuccess,
  deleteHumanSuccess,
  getHumanSuccess,
  updateHumanSuccess,
} from "./actions";

//Get profile
function* doGetHuman(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/human`,
      REQUEST_METHOD.GET
    );
    yield put(getHumanSuccess(response.data));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* getHuman() {
  yield takeLatest(GET_HUMAN, doGetHuman);
}

function* doAddHuman(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/human`,
      REQUEST_METHOD.POST,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(addHumanSuccess(response.data));
    notificationSuccess({ content: "Thêm mới thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* addHuman() {
  yield takeLatest(ADD_HUMAN, doAddHuman);
}

function* doUpdateHuman(action: Action) {
  try {
    const response: AxiosResponse = yield query(
      `/api/human`,
      REQUEST_METHOD.PUT,
      undefined,
      undefined,
      true,
      action.payload
    );
    yield put(updateHumanSuccess(response.data));
    notificationSuccess({ content: "Cập nhật thành công" });
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* updateHuman() {
  yield takeLatest(UPDATE_HUMAN, doUpdateHuman);
}

function* doDeleteHuman(action: Action) {
  try {
    yield query(
      `/api/human/${action.payload}`,
      REQUEST_METHOD.DELETE,
      undefined,
      undefined,
      true
    );
    yield put(deleteHumanSuccess(action.payload));
  } catch (error) {
    notificationServerError(error);
    // put(createCourseFailure(error as any));
  }
}

function* deleteHuman() {
  yield takeLatest(DELETE_HUMAN, doDeleteHuman);
}

export default function* humanSagas() {
  yield all([getHuman(), addHuman(), updateHuman(), deleteHuman()]);
}
